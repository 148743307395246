<template>
    <div class="h-100" @mouseleave="focusout">
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Год">
                            <b-form-input type="number"
                                          v-model="repYear"
                                          @change="loadDatas"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Область/район">
                            <b-form-select
                                v-model="region"
                                :options="listReg"
                                value-field="code"
                                @change="loadDatas"
                            ></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Категория">
                            <b-form-select
                                v-model="selectedKat"
                                :options="dictEbkDoh.dict"
                                @change="selectedCls = {id: 0, child: []}"
                            ></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Класс" :disabled="(selectedKat.child === 0)">
                            <b-form-select
                                v-model="selectedCls"
                                :options="selectedKat.child"
                                @change="selectedPcl = {id: 0, child: []}"
                            ></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Подкласс" :disabled="(selectedCls.child === 0)">
                            <b-form-select
                                v-model="selectedPcl"
                                :options="selectedCls.child"
                                @change="selectedSpf = {id: 0, child: []}"
                            ></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group label="Специфика" :disabled="(selectedPcl.child === 0)">
                            <b-form-select
                                v-model="selectedSpf"
                                :options="selectedPcl.child"
                            >
                                <template #first>
                                    <b-form-select-option :value="{id: 0}">
                                        -- Выбрать специфику --
                                    </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <b-button class="filter-add" variant="success"
                              v-show="(selectedPcl.id > 0)" @click="addItem">+
                    </b-button>
                </div>
            </b-dropdown>
          <div class="filter-actions">
              <b-button variant="success" @click="saveDatas">Сохранить</b-button>
          </div>
        </div>
        <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                :tbody-tr-class="rowClass"
                :filter="filter.search"
                :filter-included-fields="filter.on"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                        <i class="icon icon-chevron-circle" v-if="!open"></i>
                    </b-button>
                </template>
                <template #head(kat)="scope">
                    <div>КАТ</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.kat"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(cls)="scope">
                    <div>КЛ</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.cls"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(pcl)="scope">
                    <div>ПКЛ</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.pcl"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(spf)="scope">
                    <div>СП</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.spf"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(name_ru)="scope">
                    <div>Наименование</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.name_ru"
                        type="search"
                    ></b-form-input>
                </template>
                <template #cell(action)="data">
                    <b-button v-if="data.item.type === 1" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(kat)="data">
                    <div v-if="data.item.type === 1">{{ data.value }}</div>
                    <b-button v-if="data.item.type === 2" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(cls)="data">
                    <div v-if="data.item.type === 2">{{ data.value }}</div>
                    <b-button v-if="data.item.type === 3" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(pcl)="data">
                    <div v-if="data.item.type === 3">{{ data.value }}</div>
                </template>
                <template #cell(spf)="data">
                    <div v-if="data.item.type === 5">{{ data.value }}</div>
                </template>
                <template #cell(budget)="data">
                    <div class="text-right">{{ (isNaN(data.value) || (data.value === 0)) ? null : $n(data.value) }}</div>
                </template>
                <template #cell(change)="data">
                    <template v-if="(data.item.type === 5)">
                        <b-form-input class="text-right"
                                      :value="$n(data.item.change)"
                                      @change="v => data.item.change = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="noAbc"
                                      @focusout="editing = true">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ (isNaN(data.value) || (data.value === 0)) ? null : $n(data.value) }}</div>
                    </template>
                </template>
                <template #cell(amount_obk)="data">
                    <template v-if="(data.item.type === 5)">
                        <b-form-input class="text-right"
                                      :value="$n(data.item.amount_obk)"
                                      @change="v => data.item.amount_obk = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="noAbc"
                                      @focusout="editing = true">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div class="text-right">{{ (isNaN(data.value) || (data.value === 0)) ? null : $n(data.value) }}</div>
                    </template>
                </template>
                <template #cell(note)="data">
                    <textarea v-if="(data.item.type === 5)"
                              style="height: 100%"
                              cols="50"
                              v-model="data.item.note"
                              @focusout="editing = true">
                    </textarea>
                </template>
                <template #cell(more)="data">
                    <b-button @click="deleteItem(data.item)">
                        <i class="icon icon-close"></i>
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '@/services/store';

export default {
    name: 'IncomeClarify',
    props: {
        year: Number,

        obl: String,
        reg: String,
        variant: String,

        listReg: Array
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                    class: 'toggle-show'
                },
                {
                    key: 'kat',
                    label: 'КАТ',
                    class: 'toggle-show'
                },
                {
                    key: 'cls',
                    label: 'КЛ',
                    class: 'toggle-show'
                },
                {
                    key: 'pcl',
                    label: 'ПКЛ',
                       class: 'x-small'
                },
                {
                    key: 'spf',
                    label: 'СП',
                       class: 'x-small'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'budget',
                    label: 'План на год, в тыс. тенге',
                    variant: 'danger'
                },
                {
                    key: 'change',
                    label: 'Предложение по изменению, в тыс. тенге'
                },
                {
                    key: 'amount_obk',
                    label: 'Сумма, одобренная ОБК, в тыс. тенге'
                },
                {
                    key: 'note',
                    label: 'Примечание',
                    variant: 'danger'
                },
                {
                    key: 'more',
                    label: ' '
                },
                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],
            calcFlds: [
                'budget',
                'change',
                'amount_obk'
            ],

            filter: {
                kat: null,
                cls: null,
                pcl: null,
                spf: null,
                name_ru: null,
                search: null,
                on: ['filter', 'kat', 'cls', 'pcl', 'spf', 'name_ru']
            },

            dictEbkDoh: {
                dict: [],
                maps: {}
            },

            selectedKat: { id: 0, child: [] },
            selectedCls: { id: 0, child: [] },
            selectedPcl: { id: 0, child: [] },
            selectedSpf: { id: 0 },

            selectedTree: [],
            budgetForm: [],

            spfMap: new Map(),
            bdata: {
                kat: new Set(),
                budget: [],
                spfMap: new Map()
            },

            remove: {
                mark: false,
                values: []
            },

            open: true,
            editing: false,
            msg: false,
            bar: 0,
            repYear: 0,
            region: null
        };
    },

    created() {
        this.$watch('remove.mark', this.deleteDatas);

        this.$watch('filter.kat', this.filterUpdate);
        this.$watch('filter.cls', this.filterUpdate);
        this.$watch('filter.pcl', this.filterUpdate);
        this.$watch('filter.spf', this.filterUpdate);
        this.$watch('filter.name_ru', this.filterUpdate);

        this.$watch('year', this.repYear = this.year);
        this.$watch('reg', this.region = this.reg);
    },

    async mounted() {
        await this.loadBudget();
        this.bar = 20;
        await this.loadDict();
        this.bar = 100;
    },

    beforeUpdate() {
        this.budgetForm.forEach(row => {
            if ([0, 1, 2, 3].includes(row.type)) {
                row._rowVariant = 'info';
            }
        })
    },

    methods: {
        addItem() {
            const that = this;

            // поиск Категории в таблице
            const katExist = that.selectedTree.filter(function (item) {
                if (item.id === that.selectedKat.id) { return item; }
            })
            if (katExist.length === 0) { // если Категории нет в таблице -> добавляем все выбранные подэлементы
                const newKat = Object.assign({}, that.selectedKat);
                that.$set(newKat, 'child', []);
                const newCls = Object.assign({}, that.selectedCls);
                that.$set(newCls, 'child', []);
                const newPcl = Object.assign({}, that.selectedPcl);
                that.$set(newPcl, 'child', []);

                if (that.selectedSpf.id > 0) {
                    const newSpf = Object.assign({}, that.selectedSpf);
                    newPcl.child.push(newSpf);
                } else {
                    for (const spf of that.selectedPcl.child) {
                        const newSpf = Object.assign({}, spf.value);
                        newPcl.child.push(newSpf);
                    }
                }
                newCls.child.push(newPcl);
                newKat.child.push(newCls);
                that.selectedTree.push(newKat);
            } else { // если Категория имеется в таблице -> поиск Класса в Категории
                const clsExist = katExist[0].child.filter(function (item) {
                    if (item.id === that.selectedCls.id) { return item; }
                })
                if (clsExist.length === 0) { // если Класса нет в Категории(в таблице)
                    const newCls = Object.assign({}, that.selectedCls);
                    that.$set(newCls, 'child', []);
                    const newPcl = Object.assign({}, that.selectedPcl);
                    that.$set(newPcl, 'child', []);

                    if (that.selectedSpf.id > 0) {
                        const newSpf = Object.assign({}, that.selectedSpf);
                        newPcl.child.push(newSpf);
                    } else {
                        for (const spf of that.selectedPcl.child) {
                            const newSpf = Object.assign({}, spf.value);
                            newPcl.child.push(newSpf);
                        }
                    }
                    newCls.child.push(newPcl);
                    katExist[0].child.push(newCls);
                } else { // если в таблице есть выбранный Класс(в Категории из таблицы)
                    const pclExist = clsExist[0].child.filter(function (item) {
                        if (item.id === that.selectedPcl.id) { return item; }
                    })
                    if (pclExist.length === 0) { // Если в таблице есть выбранный Класс + нет выбранного Подкласса
                        const newPcl = Object.assign({}, that.selectedPcl);
                        that.$set(newPcl, 'child', []);

                        if (that.selectedSpf.id > 0) {
                            const newSpf = Object.assign({}, that.selectedSpf);
                            newPcl.child.push(newSpf);
                        } else {
                            for (const spf of that.selectedPcl.child) {
                                const newSpf = Object.assign({}, spf.value);
                                newPcl.child.push(newSpf);
                            }
                        }
                        clsExist[0].child.push(newPcl);
                    } else { // если в таблице есть выбранный Подкласс(в Классе из таблицы)
                        if (that.selectedSpf.id > 0) { // eсли Специфика выбрана
                            const spfExist = pclExist[0].child.filter(function (item) {
                                if (item.id === that.selectedSpf.id) { return item; }
                            })
                            if (spfExist.length === 0) { // Если в таблице нет выбранной Специфики
                                const newSpf = Object.assign({}, that.selectedSpf);
                                pclExist[0].child.push(newSpf);
                            } else { // Иначе
                                that.makeToast('warning', 'Сообщение',
                                    'Специфика \"' + this.selectedSpf.name_ru + '\" - уже добавлена в таблицу');
                                return;
                            }
                        } else { // eсли Специфика не выбрана, проверяем наличие всех Специфик Подкласса в таблице
                            let added = false;
                            for (const item of that.selectedPcl.child) {
                                let add = false;
                                for (const spf of pclExist[0].child) {
                                    if (item.value.id === spf.id) {
                                        add = true;
                                    }
                                }
                                if (!add) {
                                    const newSpf = Object.assign({}, item.value);
                                    pclExist[0].child.push(newSpf);
                                    added = true;
                                }
                            }
                            if (!added) {
                                that.makeToast('warning', 'Сообщение',
                                    'Подкласс \"' + this.selectedPcl.name_ru + '\" - уже добавлен в таблицу');
                                return;
                            }
                        }
                    }
                }
            }
            that.prepareDatas();
        }, // добавляет выбранное значение из фильтра в дерево

        createTable(elem, parent_id) {
            const that = this;

            const item = Object.assign({}, elem);

            that.$set(item, 'parent_id', parent_id);
            that.$set(item, 'visible', true);

            Object.defineProperty(item, 'parent', {
                get: function () {
                    for (const row of that.budgetForm) {
                        if (item.parent_id === row.id) {
                            return row;
                        }
                    }
                }
            });
            if (item.type === 5) {
                that.calcFlds.forEach(field => {
                    that.$set(item, field, 0);
                })
                that.$set(item, 'note', '');
                that.spfMap.set(that.getRowKey(item, ['kat', 'cls', 'pcl', 'spf']), item);
            } else {
                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);

                that.calcFlds.forEach(field => {
                    Object.defineProperty(item, field, {
                        get: function () {
                            return that.reSum(item, field);
                        }
                    })
                })
            }
            that.$set(item, 'index', that.budgetForm.length);
            that.$set(that.budgetForm, that.budgetForm.length, item);

            if (item.hasChild) {
                if (item.type === 1) {
                    item.child.sort(that.sortByField('cls'))
                }
                if (item.type === 2) {
                    item.child.sort(that.sortByField('pcl'))
                }
                if (item.type === 3) {
                    item.child.sort(that.sortByField('spf'))
                }
                for (const ch of item.child) {
                    that.createTable(ch, item.id);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        compareDatas() {
            const that = this;
            const saveDatas = [];
            const user = that.userLogin();

            for (const row of that.spfMap.values()) {
                const val = that.bdata.spfMap.get(that.getRowKey(row, ['kat', 'cls', 'pcl', 'spf']));

                if ((val === undefined)
                    || ((val !== undefined)
                        && !((val.change === row.change)
                        && (val.amount_obk === row.amount_obk)
                        && (val.note === row.note)))) {
                    const cost = {
                        kat: row.kat,
                        cls: row.cls,
                        pcl: row.pcl,
                        spf: row.spf,
                        change: row.change,
                        amount_obk: row.amount_obk,
                        note: row.note,
                        region: that.region,
                        year: that.repYear,
                        variant: that.variant,
                        user_name: user
                    }
                    saveDatas.push(cost);
                }
            }
            return saveDatas;
        }, // сравнивает введенные данные с ранее сохраненные и формирует массив новых записей для сохранения в БД

        async deleteDatas() {
            if (this.remove.values.length > 0) {
                const response = await fetch('/api-py/delete-income-clarify', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.remove.values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Элемент удален');
                }
                this.remove = { mark: false, values: [] };
            }

        }, // удаляет, ранее помеченные записи из БД

        deleteFromArray(arr, item) {
            arr.forEach((el, index) => {
                if (item.id === el.id) {
                    arr.splice(index, 1);
                }
            })
            this.deleteFromTable(item);
        }, // удаляет элемент дерева

        deleteFromTable(item) {
            const that = this;

            if (item.type === 5) {
                const cost = {
                    kat: parseInt(item.kat),
                    cls: parseInt(item.cls),
                    pcl: parseInt(item.pcl),
                    spf: parseInt(item.spf),
                    region: that.region,
                    year: that.repYear,
                    variant: that.variant
                };
                that.remove.values.push(cost);
            } else {
                for (const row of that.spfMap.values()) {
                    if ((item.type === 1) &&
                        (row.kat === item.kat)) {
                        const cost = {
                            kat: parseInt(item.kat),
                            cls: parseInt(item.cls),
                            pcl: parseInt(item.pcl),
                            spf: parseInt(item.spf),
                            region: that.region,
                            year: that.repYear,
                            variant: that.variant
                        };
                        that.remove.values.push(cost);
                    }
                    if ((item.type === 2) &&
                        (row.kat === item.kat) &&
                        (row.cls === item.cls)) {
                        const cost = {
                            kat: parseInt(item.kat),
                            cls: parseInt(item.cls),
                            pcl: parseInt(item.pcl),
                            spf: parseInt(item.spf),
                            region: that.region,
                            year: that.repYear,
                            variant: that.variant
                        };
                        that.remove.values.push(cost);
                    }
                    if ((item.type === 3) &&
                        (row.kat === item.kat) &&
                        (row.cls === item.cls) &&
                        (row.pcl === item.pcl)) {
                        const cost = {
                            kat: parseInt(item.kat),
                            cls: parseInt(item.cls),
                            pcl: parseInt(item.pcl),
                            spf: parseInt(item.spf),
                            region: that.region,
                            year: that.repYear,
                            variant: that.variant
                        };
                        that.remove.values.push(cost);
                    }
                }
            }
            that.remove.mark = true;
        }, // удаляет элемент из таблицы
        // удаляет, ранее помеченные записи из БД

        deleteItem(item) {
            const that = this

            that.$bvModal.msgBoxConfirm(
                'Подтвердите удаление: \"' + item.name_ru + '\"',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        // item == kat
                        if ((item.kat !== null) && (item.cls == null) && (item.pcl == null) && (item.spf == null)) {
                            that.deleteFromArray(that.selectedTree, item)
                        }
                        // item == cls
                        if ((item.cls !== null) && (item.pcl == null) && (item.spf == null)) {
                            const kat = that.selectedTree.filter(function (obj) {
                                if (obj.id === item.parent.id) { return obj }
                            })
                            if (kat[0].child.length === 1) {
                                that.deleteFromArray(that.selectedTree, kat[0]);
                            } else {
                                that.deleteFromArray(kat[0].child, item);
                            }
                        }
                        // item == pcl
                        if ((item.pcl !== null) && (item.spf == null)) {
                            const kat = that.selectedTree.filter(function (obj) {
                                if (obj.id === item.parent.parent.id) {return obj}
                            })
                            const cls = kat[0].child.filter(function (obj) {
                                if (obj.id === item.parent.id) {return obj}
                            })
                            if (cls[0].child.length === 1) {
                                if (kat[0].child.length === 1) {
                                    that.deleteFromArray(that.selectedTree, kat[0]);
                                } else {
                                    that.deleteFromArray(kat[0].child, cls[0]);
                                }
                            } else {
                                that.deleteFromArray(cls[0].child, item);
                            }
                        }
                        // item == spf
                        if (item.spf !== null) {
                            const kat = that.selectedTree.filter(function (obj) {
                                if (obj.id === item.parent.parent.parent.id) {return obj}
                            })
                            const cls = kat[0].child.filter(function (obj) {
                                if (obj.id === item.parent.parent.id) {return obj}
                            })
                            const pcl = cls[0].child.filter(function (obj) {
                                if (obj.id === item.parent.id) {return obj}
                            })
                            if (pcl[0].child.length > 1) {
                                that.deleteFromArray(pcl[0].child, item);
                            } else {
                                if (cls[0].child.length === 1) {
                                    if (kat[0].child.length === 1) {
                                        that.deleteFromArray(that.selectedTree, kat[0]);
                                    } else {
                                        that.deleteFromArray(kat[0].child, cls[0]);
                                    }
                                } else {
                                    that.deleteFromArray(cls[0].child, pcl[0]);
                                }
                            }
                        }
                        that.prepareDatas();
                    }
                })
                .catch(error => {
                    that.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        }, // удаляет элемент

        filterUpdate() {
            this.filter.search = (this.filter.kat === null ? '' : this.filter.kat) +
                (this.filter.cls === null ? '' : this.filter.cls) +
                (this.filter.pcl === null ? '' : this.filter.pcl) +
                (this.filter.spf === null ? '' : this.filter.spf) +
                (this.filter.name_ru === null ? '' : this.filter.name_ru);
            if (this.filter.search.length === 0) {
                this.filter.search = null;
            }
        }, // формирует строку поиска

        focusout() {
            if (!this.editing) {
                return;
            }
            const res = this.compareDatas();
            if ((!this.msg) && (res.length > 0)) {
                this.msg = true;
                this.$bvModal.msgBoxConfirm(
                    'Сохранить введенные данные?',
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'YES',
                        cancelTitle: 'NO',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value) {
                            this.saveVariant(res);
                        } else {
                            this.editing = false;
                        }
                        this.msg = false;
                    })
                    .catch(error => {
                        this.makeToast('danger', 'Ошибка сохранения', error.toString());
                    })
            }
        }, // срабатывает запрос на сохранения данных, при потере фокуса области ввода

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadBudget() {
            try {
                const response = await fetch('/api-py/get-income-data-by-yrv/' +
                    this.repYear + '/' + this.region + '/' + this.variant);
                this.bdata.budget = await response.json();

            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadBudget()', error.toString());
            }
        }, // данные утвержденного бюджета из BudgetIncomeData

        async loadDatas() {
            this.selectedTree.splice(0);
            this.budgetForm.splice(0);

            this.spfMap.clear();

            this.bdata.kat.clear();
            this.bdata.spfMap.clear();
            this.bdata.budget.splice(0);

            await this.loadUserDatas();
            this.bar = 100;
        },

        async loadDict() {
            const that = this;

            let items = [];
            try {
                const response = await fetch('/api-py/get-dict-income/');
                items = await response.json();
            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса loadDict()', error.toString());
                return;
            }

            const katMap = new Map(); // категория
            const clsMap = new Map(); // класс
            const pclMap = new Map(); // подкласс
            const spfMap = new Map(); // специфика

            const promise = new Promise(function(resolve, reject) {

                for (const row of items) {
                    that.$set(row, 'cls', row.clss);
                    that.$set(row, 'filter', row.kat +
                        (row.cls == null ? '' : that.padLeadingZeros(row.cls, 2)) +
                        (row.pcl == null ? '' : row.pcl) +
                        (row.spf == null ? '' : that.padLeadingZeros(row.spf, 2)) +
                        row.name_ru);

                    if (row.type < 5) {
                        row.child = [];
                    }
                    if (row.type === 1) {
                        katMap.set(that.getRowKey(row, ['kat']),
                            that.newElem('kat', row.kat, row.kat + ' - ' + row.name_ru, row));
                    }

                    if (row.type === 2) {
                        row.cls = that.padLeadingZeros(row.cls, 2);
                        clsMap.set(that.getRowKey(row, ['kat', 'cls']),
                            that.newElem('cls', row.cls, row.cls + ' - ' + row.name_ru, row));
                    }
                    if (row.type === 3) {
                        pclMap.set(that.getRowKey(row, ['kat', 'cls', 'pcl']),
                            that.newElem('pcl', row.pcl, row.pcl + ' - ' + row.name_ru, row));
                    }
                    if (row.type === 5) {
                        row.spf = that.padLeadingZeros(row.spf, 2);
                        spfMap.set(that.getRowKey(row, ['kat', 'cls', 'pcl', 'spf']),
                            that.newElem('spf', row.spf, row.spf + ' - ' + row.name_ru, row));
                    }
                }

                for (const spf of spfMap.values()) {
                    const pcl = pclMap.get(that.getRowKey(spf.value, ['kat', 'cls', 'pcl']));
                    if (pcl !== undefined) {
                        pcl.value.child.push(spf);
                    }
                }
                for (const pcl of pclMap.values()) {
                    const cls = clsMap.get(that.getRowKey(pcl.value, ['kat', 'cls']));
                    if (cls !== undefined) {
                        cls.value.child.push(pcl);
                    }
                }
                for (const cls of clsMap.values()) {
                    const kat = katMap.get(that.getRowKey(cls.value, ['kat']));
                    if (kat !== undefined) {
                        kat.value.child.push(cls);
                    }
                }
                for (const kat of katMap.values()) {
                    that.dictEbkDoh.dict.push(kat);
                }
                resolve({katMap: katMap, clsMap: clsMap, pclMap: pclMap, spfMap: spfMap});
            })
            promise.then(
                result => {
                    that.dictEbkDoh.maps = result;
                    setTimeout(() => {
                        that.loadUserDatas();
                    }, 5000)
                },
                error => {
                    that.makeToast('danger', 'Ошибка loadDict()', error.toString());
                }
            )
        }, // собирает справочник DictEbkDoh ввиде дерева с помощью Map-ов

        async loadUserDatas() {
            const that = this;

            let values = [];
            try {
                const response = await fetch('/api-py/get-income-clarify-by-yrv/' +
                    that.repYear + '/' + that.region + '/' + that.variant);
                values = await response.json();

                if (values.length === 0) {
                    that.bar = 100;
                    return;
                }
            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса loadUserDatas()', error.toString());
                return;
            }

            const katMap = new Map();
            const clsMap = new Map();
            const pclMap = new Map();
            const spfMap = new Map();
            const user = that.userLogin();

            const promise = new Promise(function(resolve, reject) {
                for (const val of values) {
                    that.$set(val, 'cls', val.clss);
                    that.bdata.kat.add(val.kat);
                    that.bdata.spfMap.set(that.getRowKey(val, ['kat', 'cls', 'pcl', 'spf']), val);

                    if (val.user_name === user) {
                        const c = that.dictEbkDoh.maps.katMap.get(that.getRowKey(val, ['kat']));
                        const kat = Object.assign({}, c.value);
                        that.$set(kat, 'child', []);
                        katMap.set(that.getRowKey(val, ['kat']), kat);

                        const cl = that.dictEbkDoh.maps.clsMap.get(that.getRowKey(val, ['kat', 'cls']));
                        const cls = Object.assign({}, cl.value);
                        that.$set(cls, 'child', []);
                        clsMap.set(that.getRowKey(val, ['kat', 'cls']), cls);

                        const p = that.dictEbkDoh.maps.pclMap.get(that.getRowKey(val, ['kat', 'cls', 'pcl']));
                        const pcl = Object.assign({}, p.value);
                        that.$set(pcl, 'child', []);
                        pclMap.set(that.getRowKey(val, ['kat', 'cls', 'pcl']), pcl);

                        const s = that.dictEbkDoh.maps.spfMap.get(that.getRowKey(val, ['kat', 'cls', 'pcl', 'spf']));
                        const spf = Object.assign({}, s.value);
                        spfMap.set(that.getRowKey(val, ['kat', 'cls', 'pcl', 'spf']), spf);
                    }
                }

                for (const spf of spfMap.values()) {
                    const pcl = pclMap.get(that.getRowKey(spf, ['kat', 'cls', 'pcl']));
                    if (pcl !== undefined) {
                        pcl.child.push(spf);
                    }
                }
                for (const pcl of pclMap.values()) {
                    const cls = clsMap.get(that.getRowKey(pcl, ['kat', 'cls']));
                    if (cls !== undefined) {
                        cls.child.push(pcl);
                    }
                }
                for (const cls of clsMap.values()) {
                    const kat = katMap.get(that.getRowKey(cls, ['kat']));
                    if (kat !== undefined) {
                        kat.child.push(cls);
                    }
                }
                for (const kat of katMap.values()) {
                    that.selectedTree.push(kat);
                }
                resolve(that.selectedTree);
            })
            promise.then(
                result => {
                    if (result.length > 0) {
                        that.prepareDatas();
                    }
                },
                error => {
                    console.log('error =>', error);
                }
            )
            katMap.clear();
            clsMap.clear();
            pclMap.clear();
            spfMap.clear();
        }, // формирует дерево из данных, введенных данным пользователем

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            })
        }, // сообщение

        newElem(field, code, text, value) {
            const el = {}
            this.$set(el, field, code)
            this.$set(el, 'text', text)
            this.$set(el, 'value', value)
            return el
        }, // формирует элемент для отображения в фильтрах

        noAbc: function (evt) {
            // const regex = new RegExp('^-?[0-9]+$');\.?
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
            if (!regex.test(key)) {
                evt.preventDefault();
                return false;
            }
        }, // вводит только цифры

        openAll() {
            this.open = !this.open;

            for (const row of this.budgetForm) {
                row.visible = this.open;
                if ([1, 2, 3, 4].includes(row.type)) {
                    row.open = this.open;
                }
                if ([0, 1].includes(row.type)) {
                    row.visible = true;
                }
            }
        }, // открывает.закрывает все ветки

        openChilds(parent, bool) {
            parent.open = (bool === undefined ? !parent.open : bool);

            for (const row of this.budgetForm) {
                if (parent.id === row.parent_id) {
                    if ([1, 2, 3].includes(row.type)) {
                        this.openChilds(row, parent.open);
                    }
                    row.visible = parent.open;
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        async prepareDatas() {
            const that = this;

            that.budgetForm.splice(0);
            that.spfMap.clear();

            that.selectedTree.sort(that.sortByField('kat'));
            for (const item of that.selectedTree) {
                await that.createTable(item, 0);
                that.bar += 10;
            }

            const total = {
                id: 0,
                type: 0,
                parent_id: -1,
                name_ru: 'Итого',
                visible: true
            }
            await that.calcFlds.forEach((field) => {
                Object.defineProperty(total, field, {
                    get: function () {
                        return that.reSum(total, field);
                    }
                })
            })
            await that.$set(total, 'index', that.budgetForm.length);
            await that.$set(that.budgetForm, that.budgetForm.length, total);

            await that.relevant();
            that.openAll();
        }, // подготовка отображения данных

        async relevant() {
            const that = this;

            await that.bdata.budget.forEach(val => {
                that.$set(val, 'cls', val.clss);
                const spf = that.spfMap.get(that.getRowKey(val, ['kat', 'cls', 'pcl', 'spf']));
                if (spf !== undefined) {
                    that.$set(spf, 'budget', val.amount);
                }
            })

            if ((that.selectedKat.id > 0) && !(that.bdata.kat.has(that.selectedKat.id))) {
                that.bdata.kat.add(that.selectedKat.kat);
                try {
                    const response = await fetch('/api-py/get-income-clarify-by-yrvkat/'
                        + that.repYear + '/' + that.region + '/' + that.variant + '/'
                        + that.selectedKat.kat);
                    const values = await response.json();

                    for (const val of values) {
                        that.$set(val, 'cls', val.clss);
                        that.bdata.spfMap.set(that.getRowKey(val, ['kat', 'cls', 'pcl', 'spf']), val);
                    }
                } catch (error) {
                    that.makeToast('danger', 'Ошибка запроса данных relevant()', error.toString());
                    return;
                }
            }

            for (const spf of that.spfMap.values()) {
                const val = that.bdata.spfMap.get(that.getRowKey(spf, ['kat', 'cls', 'pcl', 'spf']));
                if (val !== undefined) {
                    that.$set(spf, 'change', val.change);
                    that.$set(spf, 'amount_obk', val.amount_obk);
                    that.$set(spf, 'note', val.note);
                }
            }
        }, // расставляет сохранненные данные по полям

        reSum(parent, field) {
            let sum = 0;

            this.budgetForm.forEach(row => {
                if ((row.parent_id === parent.id) && (row.type !== parent.type)) {
                    sum += parseFloat(row[field]);
                }
            })
            return parseFloat(sum.toFixed(2));
        }, // пересчет суммы

        rowClass(item, type) {
            if (!item || type !== 'row') { return; }
            if (!item.visible) { return 'is-hidden'; }
        }, // задает класс 'is-hidden' заданной строке

        saveDatas() {
            const res = this.compareDatas();
            if (res.length > 0) {
                this.saveVariant(res);
            }
        }, // вызывает сохранение записей

        async saveVariant(values) {
            const that = this;
            try {
                const response = await fetch('/api-py/save-income-clarify', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();

                if ((response.status === 200) && (result.result === 'success')) {
                    for (const val of values) {
                        that.bdata.spfMap.set(that.getRowKey(val, ['kat', 'cls', 'pcl', 'spf']), val);
                    }
                    that.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка сохранения данных', error.toString());
            }
        }, // сохранение данных в БД

        sortByField(field) {
            return (a, b) => (a[field] > b[field] ? 1 : -1);
        }, // сортирует по заданному полю

        userLogin() {
            return store.state.user.login;
        } // имя пользователя
    }
};
</script>